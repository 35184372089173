<template>
    <v-card>
        <v-card-text>
            <v-text-field label="Cím" v-model="article.title"></v-text-field>
            <ejs-richtexteditor ref="defaultRTE" :height="400" :toolbar-settings="toolbarSettings">

            </ejs-richtexteditor>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="save" color="primary">Mentés</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, Table } from "@syncfusion/ej2-vue-richtexteditor";
import { getWikiArticle, patchWikiArticle, postWikiArticle } from '../api/wiki';
export default {
    provide: {
        richtexteditor: [Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, Table]
    },
    data: () => ({
        toolbarSettings: {
            items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                'LowerCase', 'UpperCase', '|',
                'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                'Outdent', 'Indent', '|',
                'CreateLink', 'Image', '|', 'ClearFormat',
                'SourceCode', 'FullScreen', '|', 'Undo', 'Redo', "|", "CreateTable"]
        },
        article: {}
    }),
    mounted: function () {
        if (this.$route.params.articleId) {
            getWikiArticle(this.$route.params.articleId).then((response) => {
                this.article = response.data;
                this.$store.commit("app/setTitle", this.article.title);
                this.$refs.defaultRTE.ej2Instances.value = this.article.content;
            }).catch((err) => {
                if (err.response.status === 404) {
                    this.$router.push({ name: "404" });
                }
            });
        }
        else {
            this.$store.commit("app/setTitle", "Új cikk");
        }
    },
    methods: {
        save: function () {
            this.article.content = this.$refs.defaultRTE.ej2Instances.getHtml();
            if (this.$route.params.articleId) {
                patchWikiArticle(this.article.id, this.article)
                    .then(() => this.$router.push({ name: "wiki.article", params: { articleId: this.article.id } }))
                    .catch((err) => alert(`Nem sikerült menteni a cikket: ${err}`));
            }
            else {
                postWikiArticle(this.article).then((response) => {
                    this.$router.push({ name: "wiki.article", params: { articleId: response.data.id } }).then(() => window.location.reload());
                });
            }
        }
    }
};
</script>